import { useState, useEffect, useContext, useRef } from "react";
import { Controller, Scene } from "react-scrollmagic";
import TravelMap from "./TravelMap/TravelMap";
import LocationCard from "./LocationCard/LocationCard";
import { locationCardData } from "./data";
import ScrollSpy from "react-ui-scrollspy";
import { MapContext } from "../../contexts/MapContext";
import { Parallax } from "react-scroll-parallax";
import useWindowDimensions from "../../hooks/useWindowDimentions";
import useOnScreen from "../../hooks/useOnScreen";

const MapContainer = () => {
  const { visualViewportWidth, width } = useWindowDimensions();

  const { updateFlyToLocationData } = useContext(MapContext);

  const elementRef = useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(elementRef);

  const [totalDuration, setTotalDuration] = useState(0);

  useEffect(() => {
    if (!isOnScreen && window.location.hash.length > 2) {
      window.history.pushState(null, "/", "#");
    }
  }, [isOnScreen]);

  useEffect(() => {
    const childScene2Duration = 500;
    const numChildScenes = locationCardData.length;

    const dynamicDuration = childScene2Duration * numChildScenes * 1.45;
    setTotalDuration(dynamicDuration);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Parallax
        translateX={[-visualViewportWidth + "px", 0 + "px", "easeOutQuint"]}
        style={{ display: "flex", marginBottom: width > 800 ? 50 : 0 }}
      >
        <h1 className="thumbsup">Honours and Awards</h1>
      </Parallax>

      <div style={{ marginTop: 0, marginBottom: 100 }}>
        <Controller>
          <div style={{ display: "flex" }}>
            <Scene
              duration={totalDuration}
              pin={true}
              enabled={true}
              offset={250}
            >
              <div
                style={{
                  width: "50vw",
                  height: "50vh",
                  marginLeft: 20,
                }}
              >
                <TravelMap />
              </div>
            </Scene>
            <div>
              {locationCardData.map(({ id }, index) => (
                <div key={id + index} data-to-scrollspy-id={id}></div>
              ))}
            </div>
            <div ref={elementRef}>
              <ScrollSpy
                onUpdateCallback={(id) => {
                  const locationData = locationCardData.find(
                    (cardData) => cardData.id === id
                  );
                  if (locationData) {
                    updateFlyToLocationData({
                      latitude: locationData.latitude,
                      longitude: locationData.longitude,
                      zoom: locationData.zoom,
                    });
                  }
                }}
                useBoxMethod
                // updateHistoryStack={false}
              >
                {locationCardData.map((cardData, index) => (
                  <Scene key={cardData.id + index} duration={500}>
                    <div
                      id={cardData.id}
                      className="location-card-outer-container"
                    >
                      <LocationCard
                        heading={cardData.heading}
                        initialSlide={cardData.initialSlide}
                        content={cardData.content}
                        media={cardData.media}
                        speed={cardData.speed}
                        badges={cardData.badges}
                      />
                    </div>
                  </Scene>
                ))}
              </ScrollSpy>
            </div>
          </div>
        </Controller>
      </div>
    </div>
  );
};

export default MapContainer;
