import { Parallax } from "react-scroll-parallax";
import useWindowDimensions from "../../hooks/useWindowDimentions";
import LazyImage from "../LazyImage/LazyImage";
import { FaGithub, FaLinkedin, FaStackOverflow } from "react-icons/fa";
import "./intro.css";

type Props = {};

const Intro = (props: Props) => {
  const { width } = useWindowDimensions();

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{}}>
          <Parallax speed={10}>
            <LazyImage
              src={process.env.PUBLIC_URL + "/images/profile-pic"}
              shimmerStyles={{ borderRadius: "50%" }}
              width={width < 800 ? 180 : "20vw"}
              height={width < 800 ? 180 : "20vw"}
              imgSytles={{
                borderRadius: "50%",
                width: width < 800 ? 180 : "20vw",
                height: width < 800 ? 180 : "20vw",
              }}
            />
          </Parallax>
        </div>

        <Parallax speed={15}>
          <h1
            style={{
              textAlign: "center",
              marginInline: 20,
              wordWrap: "normal",
            }}
          >
            Hello, I'm Hussain Pettiwala
          </h1>
        </Parallax>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            padding: 30,
          }}
        >
          <SocialButton
            href="https://www.linkedin.com/in/pettiboy/"
            icon={<FaLinkedin />}
          />
          <SocialButton
            href="https://github.com/pettiboy"
            icon={<FaGithub />}
          />
          <SocialButton
            href="https://stackoverflow.com/users/14225169/hussain-pettiwala"
            icon={<FaStackOverflow />}
          />
          <SocialButton
            href={process.env.PUBLIC_URL + "docs/resume-photo.pdf"}
            icon={
              <img
                src={process.env.PUBLIC_URL + "icons/cv.png"}
                width={25}
                style={{ filter: "invert(1)" }}
                alt="cv"
              />
            }
          />
          <SocialButton
            href={"https://devfolio.co/@pettiboy"}
            icon={
              <img
                src={process.env.PUBLIC_URL + "icons/devfolio.png"}
                width={25}
                alt="devfolio"
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

type SocialButtonProps = {
  href: string;
  icon: React.ReactNode;
};

const SocialButton = ({ href, icon }: SocialButtonProps) => {
  return (
    <a
      className="glassIcoBegin"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {icon}
    </a>
  );
};

export default Intro;
